<template>
    <div class="sim-category-info">
        <h1 class="sim-h1">
            {{ category.name }}
        </h1>

        <UiCollapseText v-if="parsedDescription.length > 400">
            <span class="sim-wysiwyg-text sim-category-info__text" v-html="parsedDescription" />
        </UiCollapseText>
        <span v-else class="sim-wysiwyg-text sim-category-info__text" v-html="parsedDescription" />

        <div v-if="subCategories?.length" class="w-full">
            <UiButton
                color="primary"
                size="large"
                class="w-full uppercase lg:hidden"
                @click="areCategoriesExpanded = !areCategoriesExpanded"
            >
                {{ $t('pages.category.show_n_subcategories', subCategories.length) }}
                <template #trailing>
                    <IconChevron :up="areCategoriesExpanded" size="xs" class="text-simplo" />
                </template>
            </UiButton>

            <BaseUtilCollapse v-model="areCategoriesExpanded" expanded-breakpoint="lg">
                <div class="sim-category-info__subcategories mt-8">
                    <NuxtLink v-for="subCategory in subCategories"
                              :key="subCategory.id!"
                              :to="subCategory.urls ?? ''"
                    >
                        <UiCardWithImage
                            class="h-full"
                            :src="subCategory.thumbnailImageUrl ?? subCategory.imageUrl ?? null"
                            hover
                        >
                            <span class="sim-text-large sim-font-bold">
                                {{ subCategory.name }}
                            </span>
                        </UiCardWithImage>
                    </NuxtLink>
                </div>
            </BaseUtilCollapse>
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    category,
    subCategories,
} = defineProps<{
    category: Partial<InstanceType<typeof CategoryModel>>
    subCategories?: Partial<InstanceType<typeof CategoryModel>>[]
}>()

const parsedDescription = computed(() => parseNewlinesIntoBr(category.description))

const areCategoriesExpanded = ref<boolean>(false)

</script>

<style scoped lang="scss">
.sim-category-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.sim-category-info__subcategories {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @include more-than(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include more-than(xl) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include more-than(xxl) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include more-than-custom(1920) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.sim-category-info__text {
    @include more-than(md) {
        line-height: 1.875rem;
    }
}

.sim-category-info__sub-img {
    width: 2.5rem;
    height: 2.5rem;

    @include more-than(md) {
        width: 3.5rem;
        height: 3.5rem;
    }
}
</style>
