<template>
    <div class="sim-collapse__text pb-8">
        <BaseUtilCollapse
            v-model="isOpen"
            :collapsed-height="106"
        >
            <slot />
        </BaseUtilCollapse>

        <div class="sim-collapse__button"
             :class="isOpen ? 'sim-collapse__button--active' : ''"
        >
            <UiButton
                class="mt-4"
                content-class="sim-link uppercase tracking-widest"
                variant="bare"
                size="none"
                @click="isOpen = !isOpen"
            >
                {{ isOpen ? $t('labels.show_less') : $t('labels.show_more') }}
                <template #trailing>
                    <IconChevron :up="isOpen" size="xs" class="text-simplo" />
                </template>
            </UiButton>
        </div>
    </div>
</template>

<script setup lang="ts">

const isOpen = ref<boolean>(false)

</script>

<style lang="scss" scoped>
.sim-collapse__text {
    position: relative;
    overflow: hidden;
}

.sim-collapse__button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include sim-text-small;
    @include sim-font-bold;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    background-color: white;

    box-shadow: 0 -10px 25px 20px rgb(255 255 255 / 1);
}

.sim-collapse__button--active {
    box-shadow: none;
}

</style>
