<template>
    <div class="flex flex-col items-baseline gap-4 sm:flex-row">
        <span class="sim-text-small sim-font-bold sim-letters-widespread text-nowrap uppercase">
            {{ $t('filters.selected_items') }}
        </span>

        <ul class="flex flex-wrap gap-3">
            <li
                v-for="item in selectedFilters"
                :key="item.id!"
                :class="{
                    'cursor-wait opacity-50': loading,
                }"
            >
                <UiBadge
                    class="!pr-0"
                    color="simplo"
                    pill
                    content-class="font-bold uppercase"
                    :loading="loading"
                >
                    <span aria-hidden="true">
                        {{ item.label }}

                        <span v-if="item.type === ProductFilterType.PRICE_SLIDER">
                            {{ currencySymbol }}
                        </span>
                    </span>

                    <button type="button"
                            :disabled="loading"
                            class="-m-2 -mr-0 p-3"
                            :class="{
                                'pointer-events-none': loading,
                            }"
                            :aria-label="$t('filters.remove_item') + ` ${item.label} ${item.type === ProductFilterType.PRICE_SLIDER ? currencySymbol : ''}`"
                            @click="removeThisFiler(item.url)"
                    >
                        <IconCross height="0.65rem" width="0.65rem" />
                    </button>
                </UiBadge>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { ProductFilterType } from '#imports'
import type { SelectedFilter } from '@simploshop-models/product-filters-response.model'

const {
    selectedFilters,
    loading,
} = defineProps<{
    selectedFilters: SelectedFilter[]
    loading?: boolean
}>()

const { currencySymbol } = useProperties()

const emit = defineEmits<{
    change: [url: string]
}>()

function removeThisFiler(url: string) {
    emit('change', url)
}
</script>
